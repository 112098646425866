<template>
  <div style="height: calc(100vh - 54px - 60px - 2vh); overflow-y: scroll; position: relative">
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.packageInfo') }}</div>
    </div>
    <el-table size="mini" :data="mergeData" border style="width: 100%" max-height="215">
      <el-table-column show-overflow-tooltip :label="$t('mergerCard.Code')" prop="Code" align="center"></el-table-column>
      <el-table-column :label="$t('mergerCard.Weight')" prop="Weight" align="center">
        <template slot-scope="scope">
          <span>{{ `${scope.row.Weight}KG` }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('mergerCard.Amount')" prop="Account" align="center"></el-table-column>
      <el-table-column :label="$t('mergerCard.Size')" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{ `${scope.row.Length}CM*${scope.row.Width}CM*${scope.row.Height}CM` }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('mergerCard.GoodName')" show-overflow-tooltip prop="GoodsName" align="center"></el-table-column>
      <el-table-column :label="$t('mergerCard.VolumeWeight')" prop="VolumeWeight" align="center">
        <template slot-scope="scope">
          <span>{{ `${scope.row.VolumeWeight}KG` }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('mergerCard.GoodsTypeName')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column :label="$t('mergerCard.Surcharge')" prop="Surcharge" align="center"></el-table-column>
    </el-table>
    <div class="u-end-flex mt20 mb20 total-text">
      <div class="mr15">{{ $t('mergerCard.totalCount') }}：{{ totalCount.count }}</div>
      <div class="mr15">{{ $t('mergerCard.totalWeight') }}：{{ totalCount.weight && totalCount.weight.toFixed(2) + 'KG' }}</div>
      <div class="mr15">{{ $t('mergerCard.totalVolumeWeight') }}：{{ totalCount.volumeWeight && totalCount.volumeWeight.toFixed(2) + 'KG' }}</div>
      <div class="mr15">{{ $t('mergerCard.totalSurcharge') }}：{{ totalCount.surcharge && totalCount.surcharge.toFixed(2) }}</div>
    </div>
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.addressInfo') }}</div>
    </div>
    <delivery-form ref="delivery" :form.sync="form" @reset="resetChannel"></delivery-form>
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.orderInfo') }}</div>
    </div>
    <order-form ref="order" :is-declare="IsDeclare" :form.sync="form"></order-form>
    <payment-form ref="payment" :form.sync="form" :is-collection-amount="VerifyOrderInfo.IsOpenCollection"></payment-form>
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.feeDetail') }}</div>
    </div>
    <div class="fee mb10">
      <div class="fee-title">{{ $t('mergerCard.feeDetail') }}</div>
      <div class="u-between-flex" style="padding: 0 3rem; align-items: flex-start">
        <div class="fee-item">
          <div class="mb20">{{ $t('mergerCard.weight') }}：{{ totalCount.weight && totalCount.weight.toFixed(2) + 'KG' }}</div>
          <div class="mb20">{{ $t('mergerCard.VolumeWeight') }}：{{ totalCount.volumeWeight && totalCount.volumeWeight.toFixed(2) + 'KG' }}</div>
          <div class="mb20">{{ $t('mergerCard.Freight') }}：{{ CalculateFee.Freight }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-popover trigger="hover" placement="right">
              <div class="popover-box">
                <p>{{ $t('mergerCard.popover.CarryWeight') }}：{{ CalculateFee.CarryWeight }}(KG)</p>
                <p>{{ $t('mergerCard.popover.FirstWeight') }}：{{ CalculateFee.FirstWeight }}(KG)</p>
                <p>{{ $t('mergerCard.popover.FirstPrice') }}：{{ CalculateFee.FirstWeightPrice }}</p>
                <p>{{ $t('mergerCard.popover.FirstWeightSurcharge') }}：{{ CalculateFee.FirstWeightSurcharge }}</p>
                <p>{{ $t('mergerCard.popover.LastWeight') }}：{{ CalculateFee.LastWeight }}(KG)</p>
                <p>{{ $t('mergerCard.popover.LastWeightUnitPrice') }}：{{ CalculateFee.LastWeightUnitPrice }}</p>
                <p>{{ $t('mergerCard.popover.LastWeightSurcharge') }}：{{ CalculateFee.LastWeightSurcharge }}</p>
              </div>
              <span slot="reference">
                <span class="fee-detail">{{ $t('mergerCard.FreightDetail') }}</span>
              </span>
            </el-popover>
          </div>
          <div style="font-weight: bold; color: #53EC7B; font-size: 1.5rem;">{{ $t('mergerCard.AccruedCharge') }}：{{ CalculateFee.Receivables }}</div>
        </div>
        <div class="fee-item">
          <div class="mb20">{{ $t('mergerCard.CalculateFeeSurcharge') }}：{{ CalculateFee.Surcharge }}</div>
          <div class="mb20">{{ $t('mergerCard.CalculateFeeAddressSurcharge') }}：{{ CalculateFee.AddressSurcharge }}</div>
          <div  class="mb20">{{ $t('mergerCard.CollectionCommission') }}：{{ CalculateFee.CollectionCommission }}</div>
          <div style="font-weight: bold; color: #53EC7B; font-size: 1.5rem">{{ `${$t('mergerCard.DestinationCharges')}(${CalculateFee.CurrencyName})：${CalculateFee.DestinationCharges}` }}</div>
        </div>
        <div class="fee-item">
          <div class="mb20">{{ $t('mergerCard.CalculateFeeStorageCharges') }}：{{ CalculateFee.StorageCharges }}</div>
          <div class="mb20">{{ $t('mergerCard.CalculateFeeLengthExceed') }}：{{ CalculateFee.LengthExceed }}</div>
          <div class="mb20">{{ $t('mergerCard.Discount') }}：{{ CalculateFee.Discount }}</div>
          <div>{{ $t('mergerCard.InsuredCommission') }}：{{ CalculateFee.InsuredCommission }}</div>
        </div>
        <div class="fee-item">
          <div class="mb20">{{ $t('mergerCard.packageCount') }}：{{ totalCount.count }}</div>
          <div class="mb20">{{ $t('mergerCard.CalculateFeeWeightExceed') }}：{{ CalculateFee.WeightExceed }}</div>
          <div class="mb20">{{ $t('mergerCard.AddedServicesFee') }}：{{ CalculateFee.AddedServicesFee }}</div>
          <div>{{ $t('mergerCard.AgenServiceFree') }}：{{ CalculateFee.AgenServiceFree }}</div>
        </div>
        <div class="fee-item">
          <div class="mb20">{{ $t('mergerCard.TaxCommission') }}：{{ CalculateFee.TaxCommission }}</div>
          <div style="color: red" class="mb20">{{ $t('mergerCard.CouponAmount') }}：{{ CalculateFee.CouponAmount }}</div>
<!--          <div>{{ $t('mergerCard.IntegralAmount') }}：{{ VerifyOrderInfo.IntegralAmount }}</div>-->
        </div>
      </div>
    </div>
    <div class="u-end-flex footer-box">
      <div class="accrued-text">￥{{ CalculateFee.Receivables }}</div>
      <el-button v-loading="loading" :disabled="loading" size="medium"  type="primary" class="sub-btn" @click="stepProcess('next')">{{ $t('mergerCard.settlement') }}</el-button>
    </div>
  </div>
</template>

<script>
import {budgetPrice, createOrder, getChannel} from "@/api/member";
import DeliveryForm from "@/components/deliveryForm.vue";
import OrderForm from "@/components/OrderForm.vue";
import PaymentForm from "@/components/PaymentForm.vue";
import { mapGetters } from "vuex"

export default {
  props: ["mergeData"],
  data() {
    return {
      loading: false,
      IsDeclare: false,
      CalculateFee: {},
      VerifyOrderInfo: {},
      OrderNo: undefined,
      form: {
        IsTax: !!this.taxMode
      },
    }
  },
  components: {
    DeliveryForm,
    OrderForm,
    PaymentForm
  },
  computed: {
    ...mapGetters(['shopID', 'userInfo', 'taxMode']),
    deliveryCallback() {
      return !!(this.form.ClientAddressId && this.form.TimerId && this.form.ChannelId && this.form.ServiceTypeId);
    },
    totalCount() {
      let count = 0;
      let weight = 0;
      let volumeWeight = 0;
      let surcharge = 0;
      this.mergeData.forEach(item => {
        count += 1;
        weight += item.Weight;
        volumeWeight += item.VolumeWeight
        surcharge += item.Surcharge;
      })
      return {
        count,
        weight,
        volumeWeight,
        surcharge
      }
    },
    formData() {
      return  {
          ShopId: this.shopID,
          ClientId: this.userInfo.Id,
          ClientAddressId: this.form.ClientAddressId,
          TimerId: this.form.TimerId,
          QuoteId: this.form.ChannelId,
          CouponId: this.form.CouponId || undefined,
          AddedServiceIds: this.form.AddedServiceIds,
          ParcelIds: this.mergeData.map(item => { return item.Id }),
          CollectionAmount: this.VerifyOrderInfo.IsOpenCollection ? this.form.CollectionAmount || undefined : undefined,
          InsuredAmount: this.form.InsuredAmount || undefined,
          IsTax: this.form.IsTax,
          DeclareName: this.form.DeclareName,
          DeclarePhone: this.form.DeclarePhone,
          DeclareAddress: this.form.DeclareAddress,
          DeclareIdCard: this.form.DeclareIdCard,
          DeclarePostCode: this.form.DeclarePostCode,
          DeclareEmail: this.form.DeclareEmail,
          DeclareTelephone: this.form.DeclareTelephone,
          DeclareMoney: this.form.DeclareMoney || undefined,
          DeclarePrice: this.form.DeclarePrice || undefined,
          DeclareAccount: this.form.DeclareAccount || undefined,
          GoodsName: this.form.GoodsName,
          GoodsName_En: this.form.GoodsName_En,
          OrderSource: 1
        }
    }
  },
  methods: {
    payOrder() {
      let that = this
      this.loading = true
      this.$refs.payment.submitForm().then(() => {
        createOrder(this.formData).then(response => {
          const { Code, Msg, Data } = response
          if (Code === 200) {
            that.$message.success(that.$t('mergerCard.orderSuccess') || "")
            that.$emit('cashier', Data.OrderNo)
          } else {
            that.$message.error(Msg);
          }
          that.loading = false
        }).catch(() => { that.loading = false })
      }).catch(() => { that.loading = false })
    },
    resetForm() {
      this.form = {
        GoodsTypeId: undefined,
        ServiceTypeId: 1,
        ChannelId: undefined,
        TimerId: undefined,
        AddedServiceIds: [],
        Integral: undefined,
        CouponId: undefined,
        ClientAddressId: undefined,
        CollectionAmount: undefined,
        InsuredAmount: undefined,
        IsTax: !!this.taxMode,
        DeclareName: undefined,
        DeclarePhone: undefined,
        DeclareAddress: undefined,
        DeclareIdCard: undefined,
        DeclarePostCode: undefined,
        DeclareEmail: undefined,
        DeclareAccount: undefined,
        DeclareTelephone: undefined,
        DeclareMoney: undefined,
        DeclarePrice: undefined,
        GoodsName: undefined,
        GoodsName_En: undefined
      }
      this.CalculateFee = {}
      this.VerifyOrderInfo = {}
      this.$refs.payment.$refs.ruleForm.resetFields()
      if(this.$refs.delivery) {
        this.$refs.delivery.init().then(() => {
          this.$refs.delivery.getTimer()
        })
      }
      if (this.$refs.payment) {
        this.$refs.payment.init()
      }
    },
    stepProcess() {
      this.payOrder()
    },
    resetChannel() {
      const data = {
        TimerId: this.form.TimerId,
        AddressId: this.form.ClientAddressId,
        ParcelIds: this.mergeData.map(item => { return item.Id })
      }
      getChannel(data).then(response => {
        this.$set(this.form, 'ChannelId', undefined)
        this.$refs.delivery.ChannelOptions = response.Data.ChannelList.map(item => {
          return { label: item.Name, value: item.Id }
        })
        this.$set(this.form, 'ChannelId', response.Data.ChannelList[0].Id)
      })
    },
    calc() {
      budgetPrice(this.formData).then(response => {
        const { Code, Data } = response
        if (Code === 200) {
          this.CalculateFee = Data.CalculateFee;
          this.VerifyOrderInfo = Data.VerifyOrderInfo;
          this.IsDeclare = this.VerifyOrderInfo.IsDeclare
          if (this.IsDeclare) {
            this.$set(this.form, 'DeclareName', this.VerifyOrderInfo.DeclareName)
            this.$set(this.form, 'DeclarePhone', this.VerifyOrderInfo.DeclarePhone)
            this.$set(this.form, 'DeclareAddress', this.VerifyOrderInfo.DeclareAddress)
            this.$set(this.form, 'DeclareTelephone', this.VerifyOrderInfo.DeclareTelephone)
            this.$set(this.form, 'DeclareIdCard', this.VerifyOrderInfo.DeclareIdCard)
            this.$set(this.form, 'DeclarePostCode', this.VerifyOrderInfo.DeclarePostCode)
            this.$set(this.form, 'DeclareMoney', this.VerifyOrderInfo.DeclareMoney)
            this.$set(this.form, 'DeclarePrice', this.VerifyOrderInfo.DeclarePrice)
            this.$set(this.form, 'DeclareAccount', this.VerifyOrderInfo.DeclareAccount)
            this.$set(this.form, 'DeclareEmail', this.VerifyOrderInfo.DeclareEmail)
            this.$set(this.form, 'GoodsName', this.VerifyOrderInfo.GoodsName)
          } else {
            if(this.VerifyOrderInfo.GoodsName && !this.form.GoodsName) {
              this.$set(this.form, 'GoodsName', this.VerifyOrderInfo.GoodsName)
            }
          }
        }
      })
    }
  },
  watch: {
    'form.TimerId': {
      handler() {
        if (!this.form.ClientAddressId) return;
        this.resetChannel()
      },
      immediate: true
    },
    form: {
      handler() {
        if (this.deliveryCallback) {
          this.calc()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  display: none;
  width: 0;
}
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: fangsong;
  .line {
    width: 5px;
    height: 15px;
    border-radius: 2.5px;
    margin-right: 8px;
    background: #E4393C;
  }
}
.footer-box {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  background: #fff;
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  .accrued-text {
    font-size: 25px;
    font-weight: 550;
    font-style: italic;
    font-family: fantasy;
    color: #E4393C;
  }
}
.total-text {
  font-size: 14px;
  font-family: fangsong;
  font-style: italic;
  font-weight: 600;
  color: #E4393C;
}
.popover-box {
  font-size: 1.2rem;
}
.pay-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fee {
  border: 0.1rem solid #EBECF0;
  .fee-title {
    background: #EFF2F8;
    font-size: 1.6rem;
    padding: 1rem 3.5rem;
  }
  .fee-item {
    padding: 1rem;
    font-size: 1.2rem;
    .fee-detail {
      color: #1890FF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
