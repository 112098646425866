<template>
  <el-dialog :before-close="close" :title="`${$t('order.trajectory')}：${carrierBillCode}`" width="30%" :visible.sync="show">
    <div class="body">
      <el-timeline reverse v-if="listData.length" class="timeline">
        <el-timeline-item
            v-for="(activity, index) in listData"
            :key="index"
            :timestamp="activity.Datatime">
          {{activity.Description}}
        </el-timeline-item>
      </el-timeline>
      <div style="text-align: center; font-size: 14px; color: #333" v-else>
        <el-image :src="require('@/static/images/member/order/empty.png')" class="empty-png"></el-image>
        <div>{{ $t('order.emptyTrajectory') }}</div>
      </div>
      <div slot="footer" class="dialog-footer mt40" style="text-align: center">
        <el-button style="width: 200px" round type="primary" @click="close">{{ $t('order.confirm') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {queryTrackLog} from "@/api/system";

export default {
  name: "TrajectoryBox",
  data() {
    return {
      loading: false,
      listData: []
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    carrierBillCode: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    show(newVal) {
      if (newVal) this.getData()
    }
  },
  methods: {
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 30,
        OrderCodes: [this.carrierBillCode]
      }
      this.loading = true
      queryTrackLog(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data;
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
/* 滚动条轨道样式 */
::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滑块背景颜色 */
  border-radius: 4px; /* 设置滑块圆角 */
}

/* 滚动条轨道hover状态样式 */
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1; /* 设置轨道hover状态时的背景颜色 */
}

/* 滚动条滑块hover状态样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滑块hover状态时的背景颜色 */
}
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .timeline {
    max-height: 250px;
    overflow-y: scroll;
    width: 90%;
  }
  .empty-png {
    width: 320px;
  }
}
</style>