<template>
  <div class="container">
    <div class="head">
      <div class="title">
        {{ $t('personal.title') }}
      </div>
    </div>
    <div class="item" v-for="item in virtualWareHouses" :key="item.Id">
      <div class="warehouse">
        <el-image :src="require('@/static/images/member/personal/warehouse.png')" class="warehouse-img"></el-image>
        <div>{{ item.Name }}</div>
      </div>
      <div class="address-box">
        <div class="u-start-flex block">
          <div class="label">
            <span>{{ $t('personal.ContactPerson') }}：<span style="color: #3F93EE">{{ item.ContactPerson }}</span></span>
            <i class="el-icon-copy-document pointer" @click="copyAddress(item.ContactPerson)" style="margin-left: 0.8rem; color: #B7CEF2"></i>
          </div>
          <div class="label">
            <span>{{ $t('personal.Phone') }}：<span style="color: #3F93EE">{{ item.Phone }}</span></span>
            <i class="el-icon-copy-document pointer" @click="copyAddress(item.Phone)" style="margin-left: 0.8rem; color: #B7CEF2"></i>
          </div>
          <div class="label">
            <span>{{ $t('personal.PostCode') }}：<span style="color: #3F93EE">{{ item.PostCode }}</span></span>
            <i class="el-icon-copy-document pointer" @click="copyAddress(item.PostCode)" style="margin-left: 0.8rem; color: #B7CEF2"></i>
          </div>
        </div>
        <div class="block" style="text-align: center">
          {{ $t('personal.DetailedAddress') }}：
          <span style="color: #3F93EE">{{ item.DetailedAddress }}</span>
          <i class="el-icon-copy-document pointer" @click="copyAddress(item.DetailedAddress)" style="margin-left: 0.8rem; color: #B7CEF2"></i>
        </div>
        <div style="width: 100%; text-align: center">
          <button class="copy-btn" @click="copyAddress(item)">{{ $t('personal.copyAddress') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {getVirtualWareHouse} from "@/api/member";
import clipboardCopy from "clipboard-copy";
export default {
  data() {
    return {
      virtualWareHouses: []
    }
  },
  computed: {
    ...mapGetters(['shopID'])
  },
  methods: {
    copyAddress(item) {
      if (typeof item === 'object') {
        const copyStr = `${item.ContactPerson}，${item.Phone}，${item.DetailedAddress}，${item.PostCode}`
        let that = this;
        clipboardCopy(copyStr)
            .then(() => {
              that.$message({
                message: this.$t('account.copySuccess'),
                type: 'success'
              });
            })
            .catch(err => {
              console.error(this.$t('account.copyTextFailed'), err);
            });
      } else {
        let that = this;
        clipboardCopy(item)
            .then(() => {
              that.$message({
                message: this.$t('account.copySuccess'),
                type: 'success'
              });
            })
            .catch(err => {
              console.error(this.$t('account.copyTextFailed'), err);
            });
      }
    },
    getData() {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        ShopId: this.shopID
      }
      getVirtualWareHouse(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.virtualWareHouses = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 1.8rem 0;
  .head {
    background-color: #F4F4F4;
    font-size: 1.6rem;
    padding: 1rem;
    font-weight: 500;
    .title {
      padding-left: 0.8rem;
      border-left: 0.5rem solid #0A82FB;
    }
  }
  .item:last-of-type {
    border-bottom: none;
  }
  .item {
    background-color: #fff;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.2rem solid #F4F4F4;
    .warehouse {
      width: 20%;
      height: 10rem;
      text-align: center;
      border-right: 1px solid #F7F7F7;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      .warehouse-img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
    }
    .address-box {
      .copy-btn {
        background-color: #FAFCFF;
        border: none;
        color: #3F93EE;
        padding: 1rem 5rem;
        font-weight: bold;
        font-size: 1.4rem;
        cursor: pointer;
      }
      width: 70%;
      .block {
        font-size: 1.4rem;
        padding: 1.5rem 0;
        width: 100%;
        background-color: #FAFCFF;
        margin: 1rem 0;
      }
      .label {
        text-align: center;
        border-right: 1px solid rgba(235,235,235,1);
        flex: 1;
        color: #6C6B67;
      }
      .label:last-of-type {
        border-right: none;
      }
    }
  }
}
</style>
