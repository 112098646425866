<template>
  <div class="card">
    <el-input :size="size" style="width: 20%" v-model="singleReports[index]['Code']" :placeholder="$t('singlecard.code')"></el-input>
    <el-input :size="size" style="width: 20%" v-model="singleReports[index]['GoodsName']" :placeholder="$t('singlecard.goodsname')"></el-input>
    <el-select :size="size" style="width: 10%" v-model="singleReports[index]['GoodsTypeId']" :placeholder="$t('singlecard.goodstype')">
      <el-option
          v-for="item in goodTypes"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id">
      </el-option>
    </el-select>
    <el-input :size="size" style="width: 10%" type="number" @change="handleChange($event, index)" v-model="singleReports[index]['Account']" :placeholder="$t('singlecard.account')"></el-input>
    <el-input :size="size" style="width: 10%" type="number" @change="handleChange($event, index)" v-model="singleReports[index]['Price']" :placeholder="$t('singlecard.price')"></el-input>
    <el-input :size="size" style="width: 10%" type="number" disabled v-model="singleReports[index]['TotalPrice']" :placeholder="$t('singlecard.totalprice')"></el-input>
    <el-input :size="size" v-if="rem" style="width: 20%" v-model="singleReports[index]['ClientRem']" :placeholder="$t('singlecard.clientrem')"></el-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: "medium"
    },
    rem: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['singleReports', 'goodTypes'])
  },
  data() {
    return {
    }
  },
  methods: {
    handleChange(val, index) {
      if (this.singleReports[index]['Account'] && this.singleReports[index]['Price']) {
        this.singleReports[index]['TotalPrice'] = parseInt(this.singleReports[index]['Account']) * parseInt(this.singleReports[index]['Price'])
      }
    }
  }
}

</script>

<style scoped lang="scss">

</style>
