<template>
  <el-form v-loading="loading" ref="ruleForm" :rules="rules" label-position="left" :model="form" label-width="100px">
    <div class="head-title" v-if="CouponOption.length">
      <div class="line"></div>
      <div>{{ $t('mergerCard.discountInfo') }}</div>
    </div>
    <div class="u-between-flex" v-if="CouponOption.length">
      <el-form-item :label="$t('mergerCard.Coupon')" prop="CouponId" style="flex-basis: 45%">
        <el-select style="width: 100%;" size="small" clearable v-model="form.CouponId" :placeholder="$t('mergerCard.placeholderCoupon')">
          <el-option
              v-for="item in CouponOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ $t('mergerCard.preferential') }}：{{ item.amount }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.CollectionAmount')" prop="CollectionAmount" style="flex-basis: 45%">
        <el-input type="number" size="mini" v-model="form.CollectionAmount"></el-input>
      </el-form-item>
<!--      <el-form-item :label="`${$t('mergerCard.Integral')}(${$t('mergerCard.available')}${Integral})`" prop="Integral" style="flex-basis: 45%">-->
<!--        <el-input type="number" size="mini" v-model="form.Integral"></el-input>-->
<!--      </el-form-item>-->
    </div>
    <div class="head-title">
      <div class="line"></div>
      <div>{{ $t('mergerCard.otherInfo') }}</div>
    </div>
    <div class="u-between-flex">
      <el-form-item size="small" :label="$t('mergerCard.AddedService')" style="flex-basis: 45%">
        <el-select style="width: 100%;" collapse-tags size="mini" multiple  v-model="form.AddedServiceIds" :placeholder="$t('mergerCard.placeholderAddedService')">
          <el-option
              v-for="item in AddedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            <span style="float: left; font-size: 1.4rem">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 1.3rem">{{ $t('mergerCard.servicePrice') }}：{{ item.price }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isCollectionAmount" :label="$t('mergerCard.CollectionAmount')" prop="CollectionAmount" style="flex-basis: 45%">
        <el-input type="number" size="small" v-model="form.CollectionAmount"></el-input>
      </el-form-item>
    </div>
    <div class="u-between-flex">
      <el-form-item :label="`${$t('mergerCard.InsuredAmount')}(${form.CurrencyName})`" prop="InsuredAmount" style="flex-basis: 45%">
        <el-input type="number" size="small" v-model="form.InsuredAmount"></el-input>
      </el-form-item>
      <el-form-item :label="$t('mergerCard.IsTax')" prop="IsTax" style="flex-basis: 45%">
        <el-select style="width: 100%;" size="small" :disabled="taxMode === 2" v-model="form.IsTax" :placeholder="$t('mergerCard.placeholderIsTax')">
          <el-option
              v-for="item in TaxOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "PaymentForm",
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    isCollectionAmount: {
      type: Blob,
      default: false
    }
  },
  data() {
    return {
      AddedOptions: [],
      CouponOption: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['Integral', 'taxMode']),
    TaxOption() {
      return [
        {
          label: this.$t('mergerCard.yes'),
          value: true
        },
        {
          label: this.$t('mergerCard.no'),
          value: false
        }
      ]
    },
    rules() {
      return {
        IsTax: [{ required: true, message: this.$t('mergerCard.placeholderIsTax'), trigger: 'blur' }],
        // AddedServiceIds: [{ required: true, message: this.$t('mergerCard.placeholderAdded'), trigger: 'blur' }]

      }
    },
  },
  methods: {
    async init() {
      await this.$store.dispatch('webSite/getCoupons', { IsUse: 1, IsEffective: 2 }).then(data => {
        this.CouponOption = data.map(item => {
          return { label: item.Name, value: item.Id, amount: item.Amount }
        })
      })
      await this.$store.dispatch('webSite/getAddedService', 1).then(data => {
        this.AddedOptions = data.map(item => {
          return { label: item.Name, value: item.Id, price: item.Price }
        })
      })
    },
    validateIntegral(rule, value, callback) {
      if (value > this.Integral) {
        callback(new Error(this.$t('mergerCard.validateIntegral') || ""));
      } else {
        callback()
      }
    },
    submitForm() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        });
      })
    },
  }
}
</script>



<style scoped lang="scss">
.head-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: fangsong;
  .line {
    width: 5px;
    height: 15px;
    border-radius: 2.5px;
    margin-right: 8px;
    background: #E4393C;
  }
}
::v-deep .el-form-item__label {
  font-size: 1.4rem;
}
::v-deep .el-form-item {
  margin-bottom: 1rem;
}
::v-deep .el-input__inner {
  height: 3.6rem !important;
}
</style>
