<template>
  <div class="box">
    <div class="item">
      <div class="label">{{ $t('pickupPoint.Name') }}：{{ item.Name }}</div>
      <div class="label">{{ $t('pickupPoint.Address') }}：{{ fullAddress }}</div>
    </div>
    <div class="item">{{ $t('pickupPoint.Rem') }}：{{ item.Rem }}</div>
    <div class="item">
      <div class="label w33">{{ $t('pickupPoint.Phone') }}：{{ item.Phone }}</div>
      <div class="label w33">{{ $t('pickupPoint.Tel') }}：{{ item.Tel }}</div>
      <div class="label w33">{{ $t('pickupPoint.Postcode') }}：{{ item.Postcode }}</div>
    </div>
    <div class="item">
      <div class="label">{{ $t('pickupPoint.ServiceCharge') }}：{{ item.ServiceCharge }}</div>
      <div class="label">{{ $t('pickupPoint.StorageLife') }}：{{ item.StorageLife }}</div>
    </div>
    <div class="item">
      <div style="color:#194D8F;" class="label w33">{{ $t('pickupPoint.AgentStateText') }}：{{ item.AgentStateText }}</div>
      <div class="label w33">{{ $t('pickupPoint.Longitude') }}：{{ item.Longitude }}</div>
      <div class="label w33">{{ $t('pickupPoint.Latitude') }}：{{ item.Latitude }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    fullAddress() {
      return `${this.item.DestinationName}${this.item.ProvinceName || ""}${this.item.CityName || ""}${this.item.AreaName || ""}${this.item.Address}`
    }
  }
}

</script>

<style scoped lang="scss">
.box {
  padding: 0.8rem;
  border: 1px solid #DCDFE6;
  border-radius: 1rem;
  .item {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 0.8rem 0;
    .label {
      width: 50%;
    }
    .w33 {
      width: 33% !important;
    }
  }
}
</style>