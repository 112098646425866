import { render, staticRenderFns } from "./deliveryForm.vue?vue&type=template&id=316413da&scoped=true&"
import script from "./deliveryForm.vue?vue&type=script&lang=js&"
export * from "./deliveryForm.vue?vue&type=script&lang=js&"
import style0 from "./deliveryForm.vue?vue&type=style&index=0&id=316413da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "316413da",
  null
  
)

export default component.exports